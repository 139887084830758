import {Box} from "@mui/material";
import cart from "../../../../../assets/icons/cart-green.svg";
import MDTypography from "../../../../../components/MDTypography";
import flecha from "../../../../../assets/icons/flecha.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import {Done} from "@mui/icons-material";
import document from "../../../../../assets/icons/document-green.svg";
import {Form, Formik, useField} from "formik";
import {useStores} from "../../../../../models";
import {openInNewTab, useApi} from "../../../../../services/helpers";
import MDButton from "../../../../../components/MDButton";
import {useState} from "react";
import ConfirmDialogModal from "../../../../../components/ConfirmDialogModal";
export const ContractSectionDone = ({projectId, order, setContracts, contracts, onOrderUpdated, loading, setLoading}) => {
  const api = useApi();
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [contractHandleOffline, setContractHandleOffline] = useState(false)
  const [noContractRequired, setNoContractRequired] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)

  const sendContract = () => {
    setLoading(true)
    api.sendContract(projectId, order.id, contracts).handle({
      onSuccess: () => onOrderUpdated(),
      errorMessage: 'Error approving contract',
      successMessage: 'Contract section handled successfully',
      onFinally: () => { setLoading(false) }
    })
  }

  const InputFile = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const {htmlFor, fileType, label, errors, bgColor, border, contractName, disable} = props;
    return (
      <>
        <label htmlFor={htmlFor}>
          <MDBox
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={1}
            px={2}
            bgColor={bgColor}
            border={border}
            borderRadius={'5px'}
            sx={{cursor: disable ? 'inherit' : 'pointer'}}
          >
            <MDTypography variant={'h4'} py={1} m={0} ml={0} sx={{color: "#004222"}}>{contractName}</MDTypography>
          </MDBox>
        </label>
        <MDTypography variant='subtitle2' color={'error'}>
          {errorText ? errorText : ''}
        </MDTypography>
      </>
    )
  }
  const DocumentUpload = ({formik, name, managerType, bgColor, border, contractName, disable, fileName, fileUrl}) => {
    const inputID = `input_${Math.random().toString().substring(2, 15)}`
    return (
      <MDBox
      >
        <input
          type='file'
          accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          name={inputID}
          id={inputID}
          disabled={managerType || disable}
          style={{display: 'none'}}
          onInput={(event) => {
            if (event && event.target.files.length > 0) {
              formik.setFieldValue(name, event.target.files[0]);
              setContracts({...contracts, [name]: event.target.files[0]});
            } else {
              formik.setFieldValue(name, null);
              setContracts({...contracts, [name]: null});
            }
          }}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        {fileUrl === null && <>
          {formik.values[name] ? (
              <>
                <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
                  <MDTypography
                    p={0} mt={1} ml={0}
                    variant={'h4'}
                    color={'tertiary'}
                    sx={{
                      fontWeight: 500,
                      textDecoration: "underline",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {formik.values[name]?.name}
                  </MDTypography>
                  <MDTypography
                    variant={'h4'} color={'tertiary'} p={0} mt={1} mx={0} sx={{cursor: 'pointer'}}
                    onClick={() => {
                      formik.setFieldValue(name, null)
                      setContracts({...contracts, [name]: null})
                    }}>Delete</MDTypography>
                </MDBox>
              </>
            ) :
            <InputFile
              htmlFor={inputID}
              errors={formik.errors}
              name={name}
              bgColor={bgColor}
              border={border}
              contractName={contractName}
              disable={disable}
            />
          }
        </>}
        {fileUrl !== null &&
          <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
            <MDTypography
              p={0} mt={1} ml={0}
              variant={'h4'}
              color={'tertiary'}
              onClick={() => openInNewTab(fileUrl)}
              sx={{
                fontWeight: 500,
                textDecoration: "underline",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
              }}
            >
              {fileName}
            </MDTypography>
          </MDBox>
        }

      </MDBox>
    )
  }

  const handleCloseModal = ()=> {
    setContracts({...contracts, contract_handled_offline: false, no_contract_required: false})
    setConfirmModal(false)
    setContractHandleOffline(false)
    setNoContractRequired(false)
  }

  return (
    <>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'}p={2}
             border={'2px solid #81D61E'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={cart} alt={"cart"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Collaboration</MDTypography>
        </MDBox>
        <Done sx={{color: "#81D61E", width: 25, height: 25}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox border={'2px solid #81D61E'} p={2} borderRadius={'xl'}>
        <MDBox display={'flex'} alignItems={'center'} gap={1} pb={1} borderBottom={'1px solid #DBDBDB'}>
          <Box component={"img"} src={document} alt={"document"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Contract</MDTypography>
        </MDBox>

        <Formik
          initialValues={{...contracts}}
          enableReinitialize
          onSubmit={values => {
            setContracts(values)
          }}
        >
          {(formik) => {
            const signedContractDisabled = formik?.values?.contract_handled_offline || formik?.values?.no_contract_required || loginStore.isSupplyManager || !(loginStore?.id === order?.buyer?.id || (order?.buyer?.is_projectmanager && loginStore.isProjectManager))
            return (
              <Form>
                {(!order.contract_handled_offline && !order.no_contract_required) && (<DocumentUpload
                  formik={formik}
                  name={'signed_contract'}
                  bgColor={(formik?.values?.contract_handled_offline || formik?.values?.no_contract_required) ? '#F5F5F5' : '#E6F7D2'}
                  border={`2px dashed ${(formik?.values?.contract_handled_offline || formik?.values?.no_contract_required) ? '#2A272E' : '#2A8457'}`}
                  contractName={'Upload Executed Contract'}
                  disable={signedContractDisabled}
                  fileName={'Signed contract'}
                  fileUrl={order?.signed_contract}
                />)}
                {(formik?.values.signed_contract && !order?.signed_contract) && (
                  <MDBox display={'flex'} justifyContent={'center'} pt={1}>
                    <MDButton
                      color={'secondary'}
                      size={'small'}
                      loading={loading}
                      disabled={loading}
                      onClick={() => setConfirmModal(true)}
                    >
                      Submit file
                    </MDButton>
                  </MDBox>
                )}
                { (!contractHandleOffline && !noContractRequired && !order.contract_handled_offline && !order.no_contract_required) &&
                  <DocumentUpload
                    formik={formik}
                    name={'manufacturer_contract'}
                    disable={!loginStore.isSupplyManager}
                    bgColor={'#F5F5F5'}
                    border={`2px dashed #2A8457`}
                    contractName={'Upload your signed contract here'}
                    fileName={'Manufacturer signed contract'}
                    fileUrl={order?.manufacturer_contract}
                  />
                }

                {(formik?.values.manufacturer_contract && !order?.manufacturer_contract) && (
                  <MDBox display={'flex'} justifyContent={'center'} pt={1}>
                    <MDButton
                      color={'secondary'}
                      size={'small'}
                      loading={loading}
                      disabled={loading}
                      onClick={() => setConfirmModal(true)}
                    >
                      Submit file
                    </MDButton>
                  </MDBox>
                )}
                {(!formik?.values.signed_contract && !order?.signed_contract) && (<MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={1}>
                  <MDButton
                    color={'primary'}
                    size={'small'}
                    loading={loading}
                    disabled={formik?.values?.signed_contract || formik?.values?.manufacturer_contract || noContractRequired || order.contract_handled_offline || order.no_contract_required}
                    sx={{ minWidth: '150px' }}
                    onClick={() => {
                      setContractHandleOffline(!contractHandleOffline)
                      setContracts({...contracts, contract_handled_offline: !contractHandleOffline})
                      setNoContractRequired(false)
                      setConfirmModal(true)
                    }}
                  >
                    {`Contract Handle Offline ${order.contract_handled_offline ? '✓' : ''}`}
                  </MDButton>
                  <MDButton
                    color={'secondary'}
                    size={'small'}
                    loading={loading}
                    disabled={formik?.values?.signed_contract || formik?.values?.manufacturer_contract || contractHandleOffline || order.no_contract_required || order.contract_handled_offline }
                    sx={{ minWidth: '150px' }}
                    onClick={() => {
                      setNoContractRequired(!noContractRequired)
                      setContracts({...contracts, no_contract_required: !noContractRequired})
                      setContractHandleOffline(false)
                      setConfirmModal(true)

                    }}
                  >
                    {`No Contract Required ${order.no_contract_required ? '✓' : ''}`}
                  </MDButton>
                </MDBox>)}
              </Form>
            )
          }}
        </Formik>
      </MDBox>
      {confirmModal && (
        <ConfirmDialogModal
          open={confirmModal}
          title={'Contract'}
          confirmText={(contractHandleOffline || noContractRequired) ? 'Handle Offline' :'Submit contract'}
          description={`Are you sure you want to ${(contractHandleOffline || noContractRequired) ? 'handle the contract offline?' : 'submit this file?'}`}
          cancelText={'Cancel'}
          handleConfirm={() => {sendContract(); setConfirmModal(false)}}
          handleClose={handleCloseModal}
        />
      )}
    </>
  )
}

