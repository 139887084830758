import {Box} from "@mui/material";
import MDTypography from "../../../../../components/MDTypography";
import flecha from "../../../../../assets/icons/flecha.svg";
import * as React from "react";
import MDBox from "../../../../../components/MDBox";
import document from "../../../../../assets/icons/document-green.svg";
import track from "../../../../../assets/icons/delivery-track.svg";
import checked from "../../../../../assets/icons/checked-grey.svg";
import {Form, Formik, useField} from "formik";
import {useStores} from "../../../../../models";
import {openInNewTab, useApi} from "../../../../../services/helpers";
import MDButton from "../../../../../components/MDButton";
import {useState} from "react";
import ConfirmDialogModal from "../../../../../components/ConfirmDialogModal";

export const DeliverySectionDone = ({projectId, order, setContracts, contracts, onOrderUpdated, loading, setLoading}) => {
  const api = useApi();
  const rootStore = useStores();
  const {loginStore} = rootStore;
  const [handleDeliveryOffline, setHandleDeliveryOffline] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  // const [loading, setLoading] = useState(false);

  const sendDeliverySchedule = () => {
    setLoading(true)
    api.sendDeliverySchedule(projectId, order.id, contracts).handle({
      onSuccess: () => onOrderUpdated(),
      errorMessage: 'Error approving contract',
      successMessage: `Delivery schedule ${handleDeliveryOffline ? 'handle offline' : 'file sent'}`,
      onFinally: () => { setLoading(false) }
    })
  }

  const InputFile = (props) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    const {htmlFor, fileType, label, errors, bgColor, border, contractName, disable} = props;
    return (
      <>
        <label htmlFor={htmlFor}>
          <MDBox
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            mb={1}
            px={2}
            bgColor={bgColor}
            border={border}
            borderRadius={'5px'}
            sx={{cursor: disable ? 'pointer' : 'inherit'}}
          >
            <MDTypography variant={'h4'} py={1} m={0} ml={0} sx={{color: "#004222"}}>{contractName}</MDTypography>
          </MDBox>
        </label>
        <MDTypography variant='subtitle2' color={'error'}>
          {errorText ? errorText : ''}
        </MDTypography>
      </>
    )
  }
  const DocumentUpload = ({formik, name, managerType, bgColor, border, contractName, disable, fileName, fileUrl}) => {
    const inputID = `input_${Math.random().toString().substring(2, 15)}`
    return (
      <MDBox
      >
        <input
          type='file'
          accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          name={inputID}
          id={inputID}
          disabled={disable}
          style={{display: 'none'}}
          onInput={(event) => {
            if (event && event.target.files.length > 0) {
              formik.setFieldValue(name, event.target.files[0]);
              setContracts({...contracts, [name]: event.target.files[0]});
            } else {
              formik.setFieldValue(name, null);
              setContracts({...contracts, [name]: null});
            }
          }}
          onClick={(event) => {
            event.target.value = null
          }}
        />
        {fileUrl === null && <>
          {formik.values[name] ? (
              <>
                <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
                  <MDTypography
                    p={0} mt={1} ml={0}
                    variant={'h4'}
                    color={'tertiary'}
                    sx={{
                      fontWeight: 500,
                      textDecoration: "underline",
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {formik.values[name]?.name}
                  </MDTypography>
                  <MDTypography
                    variant={'h4'} color={'tertiary'} p={0} mt={1} mx={0} sx={{cursor: 'pointer'}}
                    onClick={() => {
                      formik.setFieldValue(name, null)
                      setContracts({...contracts, [name]: null})
                    }}>Delete</MDTypography>
                </MDBox>
              </>
            ) :
            <InputFile
              htmlFor={inputID}
              errors={formik.errors}
              name={name}
              bgColor={bgColor}
              border={border}
              contractName={contractName}
              disable={disable}
            />
          }
        </>}
        {fileUrl !== null &&
          <MDBox key={`id-${name}`} display={'flex'} justifyContent={'space-between'}>
            <MDTypography
              p={0} mt={1} ml={0}
              variant={'h4'}
              color={'tertiary'}
              onClick={() => openInNewTab(fileUrl)}
              sx={{
                fontWeight: 500,
                textDecoration: "underline",
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
              }}
            >
              {fileName}
            </MDTypography>
          </MDBox>
        }

      </MDBox>
    )
  }

  return (
    <>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox border={'2px solid #81D61E'} p={2} borderRadius={'xl'}>
        <Formik
          initialValues={{delivery_schedule: null, delivery_handled_offline: null, delivery_schedule_handled_offline: handleDeliveryOffline}}
          enableReinitialize
          onSubmit={values => {
            setContracts(values)
          }}
        >
          {(formik) => {
            return (
              <Form>
                <MDBox display={'flex'} alignItems={'center'} gap={1} py={2} borderBottom={'1px solid #DBDBDB'}>
                  <Box component={"img"} src={document} alt={"document"} sx={{width: 24, height: 24}}/>
                  <MDTypography variant={'h6'}>Delivery Schedule</MDTypography>
                </MDBox>
                {
                  !order.delivery_schedule_handled_offline && (
                    <DocumentUpload
                      formik={formik} name={'delivery_schedule'}
                      bgColor={(formik?.values?.delivery_schedule_handled_offline) ? '#F5F5F5' : '#E6F7D2'}
                      border={`2px dashed ${(formik?.values?.delivery_schedule_handled_offline) ? '#2A272E' : '#2A8457'}`}
                      contractName={'Upload your delivery schedule'}
                      disable={handleDeliveryOffline || !(loginStore?.id === order?.buyer?.id || (order?.buyer?.is_projectmanager && loginStore.isProjectManager))}
                      fileName={'Delivery schedule'}
                      fileUrl={order?.delivery_schedule}
                    />
                  )
                }
                {(formik?.values.delivery_schedule && !order?.delivery_schedule) && (
                  <MDBox display={'flex'} justifyContent={'center'} pt={1}>
                    <MDButton
                      color={'secondary'}
                      size={'small'}
                      disabled={loading}
                      loading={loading}
                      onClick={() => setConfirmModal(true)}
                    >
                      Submit file
                    </MDButton>
                  </MDBox>
                )}
                {(!formik?.values.delivery_schedule && !order?.delivery_schedule) && (
                  <MDBox display={'flex'} justifyContent={'center'} pt={1}>
                    <MDButton
                      color={'secondary'}
                      size={'small'}
                      loading={loading}
                      disabled={formik?.values?.delivery_schedule || loading || order.delivery_schedule_handled_offline }
                      onClick={() => {
                        setHandleDeliveryOffline(true)
                        setContracts({...contracts, delivery_schedule_handled_offline: !handleDeliveryOffline})
                        setConfirmModal(true)
                      }}
                    >
                      {`Delivery Schedule Handle Offline ${order.delivery_schedule_handled_offline ? '✓' : ''}`}
                    </MDButton>
                  </MDBox>
                )}
              </Form>
            )
          }}
        </Formik>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2}
             border={'2px solid #DBDBDB'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={track} alt={"track"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Fulfillment</MDTypography>
        </MDBox>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'center'}>
        <Box component={"img"} src={flecha} alt={"flecha"} sx={{width: 24, height: 24}}/>
      </MDBox>
      <MDBox display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderRadius={'xl'} p={2}
             border={'2px solid #DBDBDB'} sx={{height: '58px'}}>
        <MDBox display={'flex'} alignItems={'center'} gap={1}>
          <Box component={"img"} src={checked} alt={"checked"} sx={{width: 24, height: 24}}/>
          <MDTypography variant={'h6'}>Complete</MDTypography>
        </MDBox>
      </MDBox>
      {confirmModal && (
        <ConfirmDialogModal
          open={confirmModal}
          title={'Delivery Schedule'}
          confirmText={handleDeliveryOffline ? 'Handle Offline' : 'Submit'}
          description={`Are you sure you want to ${handleDeliveryOffline ? 'handle this offline?' : 'submit this file?'}`}
          cancelText={'Cancel'}
          handleConfirm={() => {sendDeliverySchedule(); setConfirmModal(false)}}
          handleClose={() => setConfirmModal(false)}
        />
      )}
    </>
  )
}

