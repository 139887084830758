import DashboardProjectManagerLayout from "../../../components/DashboardProjectManager";
import {observer} from "mobx-react";
import DataTable from "../../../components/DataTable";
import {useState} from "react";
import {showMessage, useApi} from "../../../services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import {useParams} from "react-router-dom";

const ContactList = () => {
  const api = useApi()
  const {id: projectId} = useParams();
  const [loading, setLoading] = useState(false);
  const [datatable, setDatatable] = useState({...dataTableModel});
  const [paginationData, setPaginationData] = useState({counts: 0, itemsPerPage: 0, currentPage: 1});
  const getProjectContactList = () => {
    setLoading(true)
    api.getProjectContactList(projectId).then((result) => {
      if (result.kind === "ok") {
        const results = result.data
        const tmp = {...dataTableModel}
        tmp.rows = result.data.map(e => renderTableRow(e))
        setDatatable(tmp)
        setPaginationData(value => ({...value, counts: results.length, itemsPerPage: results.length}))
      }
    })
      .catch(err => {
        console.log(err)
        showMessage()
      })
      .finally(() => setLoading(false))
  }
  return (
    <DashboardProjectManagerLayout
      title={'Contact List'}
    >
      <DataTable
        loading={loading}
        table={datatable}
        currentPage={paginationData.currentPage}
        numberOfItems={paginationData.counts}
        numberOfItemsPage={paginationData.itemsPerPage}
        searchFunc={getProjectContactList}
        searchQuery={''}
        onPageChange={page => {
          getProjectContactList('', page)
          setPaginationData(value => ({...value, currentPage: page}))
        }}
      />
    </DashboardProjectManagerLayout>
  )
}
export default observer(ContactList);
